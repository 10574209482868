<template>
  <div class="header-navigation" :class="`header-navigation-${color}`">
    <component
      :is="`IconMenu${color.charAt(0).toUpperCase() + color.slice(1)}`"
      class="header-navigation__icon"
      @click="openSidebar('menu')"
    />
    <div
      class="header-navigation__items"
      v-if="ready && isDesktop && menuMainItems.length > 0"
    >
      <CustomCta
        v-for="(item, index) in menuMainItems"
        :key="index"
        class="header-navigation__items__item"
        type="label"
        :has-icon="false"
        @click="handleHeaderClick(item.label)"
      >
        <span
          class="header-navigation__items__item__label"
          :class="{ 'promo-label': isPromoLabel(item.label) }"
        >
          {{ item.label }}
        </span>
      </CustomCta>
    </div>
  </div>
</template>

<script>
import {
  ref,
  useFetch,
  defineComponent,
  useRouter,
  useContext,
} from '@nuxtjs/composition-api';
import { useMenu } from '@gemini-vsf/composables';
import { useUiState, useWindow } from '~/composables';
import { CustomCta } from '~/components/General';
import { IconMenuBlack, IconMenuWhite } from '~/components/General/Icons';

export default defineComponent({
  name: 'HeaderNavigation',
  components: {
    CustomCta,
    IconMenuBlack,
    IconMenuWhite,
  },
  props: {
    color: {
      type: String,
      default: 'black',
      validator(value) {
        return ['black', 'white'].includes(value);
      },
    },
  },
  setup() {
    const { menu, search: searchMenu } = useMenu('mainmenu');
    const { menu: navMenu, search: searchNavMenu } =
      useMenu('headernavigation');
    const router = useRouter();
    const { app } = useContext();
    const { ready, isDesktop } = useWindow();
    const { openSidebar } = useUiState();
    const menuMainItems = ref([]);
    const promoLabelsArray = new Set([
      'Promotions',
      'Promozioni',
      'Promociones',
      'Promotions',
      'Sonderangebote',
      'Double Black Friday',
      'Double Cyber Monday',
    ]);

    const handleHeaderClick = (label) => {
      const isInSidebar = menu.value?.items?.find(
        (item) => item.label === label
      );
      if (
        isInSidebar &&
        isInSidebar.children &&
        isInSidebar.children.length > 0
      ) {
        openSidebar('menu', { selectedParent: label });
      } else {
        router.push(app.localePath(isInSidebar.link));
      }
    };
    const isPromoLabel = (menuLabel) => promoLabelsArray.has(menuLabel);
    useFetch(async () => {
      try {
        await searchNavMenu({ code: 'headernavigation' });
        await searchMenu({ code: 'mainmenu' });
        menuMainItems.value = navMenu.value?.items;
      } catch {
        console.info('Could not get mainmenu menu content');
      }
    });
    return {
      isPromoLabel,
      ready,
      isDesktop,
      menuMainItems,
      openSidebar,
      handleHeaderClick,
    };
  },
});
</script>

<style lang="scss" scoped>
.header-navigation {
  display: flex;
  align-items: center;
  margin: 0 1.25rem;
  &__icon {
    margin: 0 0.1875rem;
    cursor: pointer;
  }
  &__items {
    display: flex;
    align-items: center;
    &__item {
      margin-left: 1.25rem;
      &__label {
        @include desktop-h7;
        &.promo-label {
          color: var(--c-red-error);
        }
      }
    }
  }
  &-white {
    .header-navigation__items {
      &__item {
        &__label {
          color: var(--c-white);
          &.promo-label {
            color: var(--c-red-error);
          }
        }
      }
    }
  }
}
</style>
