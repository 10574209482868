<template>
  <div>
    <div
      class="app-header"
      :class="[
        `app-header-${headerColor}`,
        { 'app-header__sticky': stickyHeader },
      ]"
    >
      <div class="app-header__inner">
        <div class="app-header__inner__left">
          <HeaderNavigation v-if="!simpleHeader" :color="headerColor" />
          <GoBackButton
            v-else
            :color="headerColor"
            class="app-header__inner__left__go-back"
          />
        </div>
        <div class="app-header__inner__center">
          <HeaderLogo :color="headerColor" />
        </div>
        <div class="app-header__inner__right">
          <HeaderIcons v-if="!simpleHeader" :color="headerColor" />
        </div>
      </div>
    </div>
    <div
      v-if="stickyHeader && routeHeaderColor !== 'white'"
      class="app-header-placeholder"
    />
  </div>
</template>

<script>
import { computed, defineComponent, useRoute } from '@nuxtjs/composition-api';
import { useWindow } from '~/composables';
import { HeaderIcons, HeaderLogo, HeaderNavigation, GoBackButton } from '.';

export default defineComponent({
  name: 'AppHeader',
  components: {
    HeaderIcons,
    HeaderNavigation,
    HeaderLogo,
    GoBackButton,
  },
  setup() {
    const route = useRoute();
    const { stickyHeader } = useWindow();

    const routeHeaderColor = computed(
      () => route.value?.meta?.headerColor ?? ''
    );

    const headerColor = computed(() =>
      !stickyHeader.value && routeHeaderColor.value
        ? routeHeaderColor.value
        : 'black'
    );
    const simpleHeader = computed(() => route.value?.meta?.simpleHeader);

    return {
      headerColor,
      simpleHeader,
      stickyHeader,
      routeHeaderColor,
    };
  },
});
</script>

<style lang="scss" scoped>
.app-header {
  background-color: var(--c-white);
  transition: background-color 0.5s;
  z-index: 2;
  width: 100%;
  height: 3.875rem;
  @include desktop-boxed;
  &-white {
    background-color: transparent;
    position: absolute;
  }
  &__inner {
    height: 3.875rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__left,
    &__center,
    &__right {
      width: 100%;
      display: flex;
      align-items: center;
    }
    &__left {
      justify-content: flex-start;
      &__go-back {
        margin: 0 1.4375rem;
      }
    }
    &__center {
      justify-content: center;
    }
    &__right {
      justify-content: flex-end;
    }
  }
  &__sticky {
    position: fixed;
    top: 0;
    z-index: 3;
    .app-header__inner {
      width: 100%;
      @include desktop-boxed;
      margin: 0 auto;
    }
  }
  &-placeholder {
    height: 3.875rem;
  }
  @include from-desktop-min {
    height: 5.125rem;
    &__inner {
      height: 5.125rem;
    }
    &-placeholder {
      height: 5.125rem;
    }
  }
}
</style>
