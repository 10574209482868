<template>
  <CustomCta type="icon" class="go-back-button" @click="goBackClick">
    <component
      :is="`IconGoBack${color.charAt(0).toUpperCase() + color.slice(1)}`"
    />
  </CustomCta>
</template>

<script>
import { useRouter, defineComponent } from '@nuxtjs/composition-api';
import { CustomCta } from '~/components/General';
import { IconGoBackBlack, IconGoBackWhite } from '~/components/General/Icons';

export default defineComponent({
  name: 'GoBackButton',
  components: {
    CustomCta,
    IconGoBackBlack,
    IconGoBackWhite,
  },
  props: {
    color: {
      type: String,
      default: 'black',
      validator(value) {
        return ['black', 'white'].includes(value);
      },
    },
    routerGoBack: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();

    const goBackClick = () => {
      if (props.routerGoBack) {
        router.go(-1);
      } else {
        emit('click');
      }
    };

    return {
      goBackClick,
    };
  },
});
</script>

<style lang="scss" scoped></style>
