<template>
  <div class="header-icons" :class="`header-icons-${color}`">
    <div class="header-icons__icons" v-if="ready && isDesktop">
      <CustomCta
        type="label"
        :has-icon="false"
        class="header-icons__icons__icon"
        @click="openSidebar('country')"
      >
        <span class="header-icons__icons__icon__label">
          {{ marketAndCurrencyLabel }}
        </span>
      </CustomCta>
    </div>
    <div class="header-icons__icons" v-if="ready">
      <component
        v-if="isDesktop"
        :is="`IconUser${color.charAt(0).toUpperCase() + color.slice(1)}`"
        class="header-icons__icons__icon"
        @click="handleUserClick"
      />
      <component
        v-if="isDesktop"
        :is="`IconHeart${color.charAt(0).toUpperCase() + color.slice(1)}${
          wishlistTotalItems > 0 ? 'Full' : ''
        }`"
        class="header-icons__icons__icon"
        @click="handleWishlistClick"
      />
      <component
        v-if="!isSearchPage"
        :is="`IconSearch${color.charAt(0).toUpperCase() + color.slice(1)}`"
        class="header-icons__icons__icon"
        @click="toggleSearchModal"
      />
      <component
        :is="`IconBag${color.charAt(0).toUpperCase() + color.slice(1)}`"
        class="header-icons__icons__icon header-icons__icons__icon__bag"
        @click="handleBagClick"
      />
      <span
        class="header-icons__icons__number"
        @click="handleBagClick"
        v-if="cartTotalItems && cartTotalItems > 0"
      >
        {{ cartTotalItems }}
      </span>
    </div>
  </div>
</template>

<script>
import {
  useContext,
  useRouter,
  computed,
  defineComponent,
  watch,
  ref,
  useRoute,
} from '@nuxtjs/composition-api';
import { wishlistGetters, useUser } from '@gemini-vsf/composables';
import { useWishlist, useUiState, useWindow, useMarkets } from '~/composables';
import { CustomCta } from '~/components/General';
import {
  IconUserBlack,
  IconUserWhite,
  IconHeartBlack,
  IconHeartWhite,
  IconSearchBlack,
  IconHeartBlackFull,
  IconHeartWhiteFull,
  IconSearchWhite,
  IconBagBlack,
  IconBagWhite,
} from '~/components/General/Icons';
import { useCart } from '~/composables';

export default defineComponent({
  name: 'HeaderIcons',
  components: {
    CustomCta,
    IconUserBlack,
    IconUserWhite,
    IconHeartBlack,
    IconHeartWhite,
    IconSearchBlack,
    IconSearchWhite,
    IconBagBlack,
    IconBagWhite,
    IconHeartBlackFull,
    IconHeartWhiteFull,
  },
  props: {
    color: {
      type: String,
      default: 'black',
      validator(value) {
        return ['black', 'white'].includes(value);
      },
    },
  },
  setup() {
    const { ready, isDesktop } = useWindow();
    const router = useRouter();
    const route = useRoute();
    const {
      app: { localeRoute },
    } = useContext();
    const { isAuthenticated } = useUser();
    const { openSidebar, toggleSearchModal } = useUiState();
    const { wishlist } = useWishlist();
    const wishlistItems = computed(() =>
      wishlistGetters?.getItems(wishlist?.value)
    );
    const wishlistTotalItems = computed(() => wishlistItems?.value?.length);
    const { marketAndCurrencyLabel } = useMarkets();
    const { cartTotalItems } = useCart();

    const handleWishlistClick = async () => {
      await router.push(localeRoute({ name: 'wishlist' }));
    };
    const handleUserClick = async () => {
      if (isAuthenticated.value) {
        await router.push(localeRoute({ name: 'my-account' }));
      } else {
        openSidebar('account');
      }
    };
    const handleBagClick = async () => {
      if (isDesktop.value) {
        openSidebar('minicart');
      } else {
        await router.push(localeRoute({ name: 'cart' }));
      }
    };

    // check if the route is fo the search page
    const isSearchPage = ref(false);

    watch(
      () => route.value.fullPath,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          isSearchPage.value = /\/search/.test(newValue);
        }
      },
      { immediate: true }
    );

    return {
      handleWishlistClick,
      handleUserClick,
      handleBagClick,
      openSidebar,
      wishlistTotalItems,
      isDesktop,
      ready,
      marketAndCurrencyLabel,
      toggleSearchModal,
      cartTotalItems,
      isSearchPage,
    };
  },
});
</script>

<style lang="scss" scoped>
.header-icons {
  display: flex;
  align-items: center;
  &__icons {
    display: flex;
    align-items: center;
    position: relative;
    &__icon {
      margin-right: 1.25rem;
      cursor: pointer;
      &:first-child {
        margin-left: 1.25rem;
      }
      &__label {
        @include custom-font-13x13-400;
      }
    }
    &:not(:first-child) {
      border-left: var(--general-border);
    }
    &__number {
      position: absolute;
      bottom: -0.375rem;
      right: 1rem;
      color: var(--c-white);
      background: var(--c-black);
      border-radius: 50%;
      width: 1.0625rem;
      height: 1.0625rem;
      font-family: var(--font-family-secondary);
      font-weight: 400;
      line-height: 0.4375rem;
      font-size: 0.625rem;
      @include flex-center;
    }
  }
  &-white {
    .header-icons__icons {
      &__icon {
        &__label {
          color: var(--c-white);
        }
      }
      &:not(:first-child) {
        border-color: var(--c-white);
      }
    }
  }
}
</style>
