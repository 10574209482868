<template>
  <nuxt-link :to="localePath('/')" class="header-logo">
    <SfImage class="sf-header__logo-img" v-bind="logoProps" />
  </nuxt-link>
</template>

<script>
import { SfImage } from '@storefront-ui/vue';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useWindow } from '~/composables';

export default defineComponent({
  name: 'HeaderLogo',
  components: { SfImage },
  props: {
    color: {
      type: String,
      default: 'black',
      validator(value) {
        return ['black', 'white'].includes(value);
      },
    },
  },
  setup(props) {
    const { isDesktop } = useWindow();

    const logoProps = computed(() => ({
      title: 'Sogni di cristallo',
      alt: 'Sogni di cristallo',
      height: isDesktop.value ? 22 : 14,
      width: isDesktop.value ? 242 : 165,
      src: `/logo/logo-${props.color}.svg`,
      placeholder: '',
      'image-tag': 'nuxt-img',
    }));

    return {
      logoProps,
    };
  },
});
</script>

<style lang="scss" scoped>
.header-logo {
  height: 22px;
  width: 242px;
  @include to-tablet-max {
    height: 14px;
    width: 165px;
  }
}
</style>
